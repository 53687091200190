/* ==========================================================================
   Slider icons
   ========================================================================== */

  .card {
    .icons & {
      background: none;
      border: none;
  
      /* Card - Image */
      .card-image {
        padding: 0 15px;
        @extend .text-center;
        font-size: 3.5rem;
        transition: $transition-base;
        a {
          color: $white;
          &:hover {
            color: $theme-color-primary;
          }
        }
        &:hover {
          color: $theme-color-primary;
          font-size: 3.25rem;
        }
      }
    
      /* Card - Body */
      .card-body {
        .card-caption {
    
          .card-title {
            @extend .text-center;
            font-size: 0.875rem; 
            line-height: 1.25rem;
            font-weight: 400!important;
            font-style: italic;
            color: $white;
          }

          .card-btn,
          .card-subtitle,
          .card-text {
            @extend .d-none;
          }
        }
      }
    }
    
  }
