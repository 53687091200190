.vocabulary_term_collection {
  @include make-row();
}

.image_collection {
  @include make-row();
  margin: 0;

  .item {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}