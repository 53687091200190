/* ==========================================================================
   Cards
   ========================================================================== */

.card {
  @extend .h-100;
  @extend .border-0;
  border-radius: 0;

  /* Card - Image */
  .card-image {
    .service_overview &,
    .assortiment_category_overview &,
    .assortiment_overview &,
    .accommodation_overview &,
    .accommodation_category_overview &,
    .blog_overview &,
    .service_overview &,
    .service_category &,
    .service_category_overview {
      box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.2);
      padding: 15px;
    }
    .card-image-link {
      @extend .d-block;
    }
    .card-caption {
      .card-label {
        @extend .position-absolute;
        @extend .px-3;
        @extend .py-1;
        @extend .mt-2;
        background: $theme-color-secondary;
        font-size: 1rem;
        font-family: $font-family-secondary;
        font-weight: bold;
      }
    }
    img {
      border-radius: 0;
    }
    transition: $transition-base;
    &:hover {
      transform: matrix3d(0.94, 0, 0.34, -0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(-10px)
    }
  }

  /* Card - Body */
  .card-body {
    .card-caption {
      @extend .d-flex;
      @extend .flex-column;
      @extend .h-100;
    }

    .card-title-link {
      @extend .d-block;
    }
    body:not(.home) & {

      .card-title {
        @extend .tt-content-h4;
        margin-bottom: 10px!important;
      }

      .card-subtitle {
        @extend .tt-content-h3;
        font-size: 0.875rem;
        color: $theme-color-primary;
      }
    }

    .card-btn {
      @extend .align-self-start;
      @extend .mt-auto;
      @extend .btn-link--primary;
      @extend .add-arrow--after;
    }

    .price {
      @extend .tt-card-price;
    }

    .card-text {
      ul {
        @extend .m-0;
        @extend .p-0;
        list-style: none;
      }
    }
  }

  /* Card - Img-overlay */
  .card-img-overlay {
    background-color: rgba($black, 0.8);

    .card-caption {
      @extend .text-white;
    }

    .card-title {
      @extend .mb-1;

      font-size: $font-size-base * 1.375;
      @extend .font-weight-bold;
    }

    .card-subtitle {
      @extend .mb-1;
      
      font-size: $font-size-base * 1.125;
    }

    .card-btn {
      @extend .btn-link--primary;
    }
  }

/* Card - Wide
   ========================================================================== */
  &.wide {
    @extend .d-flex;
    @extend .flex-row;
    @extend .flex-wrap;

    /* Card - Image */
    .card-image {
      align-self: start;
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }
      
      @extend .p-0;
    }

    /* Card - Body */
    .card-body {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(8);
      }

      .card-caption {
        .card-btn {
          @extend .mr-auto; 
        }
      }
    }
  }
}