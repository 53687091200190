@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$blue:    #1C3C4D;
$cyan:    #0D8BCB;
$yellow:  #DCC481;

$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #EF5900;
$green:   #bbd331;
$teal:    #20c997;


$dark-gray:		#1a2226;
$light-gray:	#b8bec3;

$primary:       $blue;
$secondary:     $yellow;
$tertiary:			$cyan;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: (
	"primary":    $primary,
	"secondary":  $secondary,
	"tertiary":		$tertiary,
	"success":    $success,
	"info":       $info,
	"warning":    $warning,
	"danger":     $danger,
	"light":      $light,
	"dark":       $dark
);


// Body
// 
// Settings for the `<body>` element.

$body-bg:                   	$white;
$body-color:                	$gray-900;
$bg-blue: 										theme-color("primary");
$bg-yellow: 									theme-color("secondary");
$bg-cyan: 										theme-color("tertiary");

// Links
//
// Style anchor elements.

$link-color:              		theme-color("tertiary");
$link-decoration:         		none;
$link-hover-color:        		darken($link-color, 15%);
$link-hover-decoration:				underline;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   	1rem;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;
$eyecatcher-space: 						25%;
$eyecatcher-space-sm: 				70px;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      'Roboto', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
$font-family-secondary:       'Caveat', serif;

$font-size-base:							1rem; // Assumes the browser default, typically `16px`
$font-size-secondary:					0.875rem;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.75;
$h2-font-size:                $font-size-base * 2.75;
$h3-font-size:                $font-size-base * 1.125;
$h4-font-size:                $font-size-base * 1.875;
$h5-font-size:                $font-size-base * 1.875;
$h6-font-size:                $font-size-base;
$h1-font-size-sm:							$font-size-base * 1.875;
$h3-font-size-sm:							$font-size-base * 1;

// 3W MEDIA - CUSTOM STYLING

// theme styling
$theme-color-primary: 				$blue;
$theme-color-primary-dark: 		darken($theme-color-primary, 10%);

$theme-color-secondary: 			$yellow;
$theme-color-secondary-dark: 	darken($theme-color-secondary, 10%);

$theme-color-tertiary: 				$cyan;
$theme-color-tertiary-dark: 	darken($theme-color-tertiary, 10%);

// Eyecatcher
$eyecatcher-height-big: 			70vh;
$eyecatcher-height-small: 		35vh;
$eyecatcher-height-big-sm: 		350px;
$eyecatcher-height-small-sm: 	200px;

// TOMMY
$zeb-color-primary: 			$theme-color-primary;
$zeb-color-secondary: 		$theme-color-secondary;
$zeb-color-tertiary: 			$theme-color-tertiary;

$py-big-s: 60px;
$py-big-l: 100px;

// MENU
$menu-py: 30px;
$menu-py-sm: 15px;
$menu-px: 18px;
$menu-px-sm: 18px;

// DETAIL PAGE CONTAINER WIDTHS
$container-detail-mw: 1400px;

// BG SECTION
.bg-blue {
	background: $bg-blue;
	color: rgba($white, 0.8);
	h3 {
		color: $white;
	}
	h1, h2 {
		color: $theme-color-secondary;
	}
}
.bg-sand {
	background: $bg-yellow;
}
.bg-cyan {
	background: $bg-cyan;
	color: $white;
}