/* ==========================================================================
   Base
   ========================================================================== */

/* Body
   ========================================================================== */
body.default {
  @include media-breakpoint-up(xl) {
    padding-top: 81px;
    
    transition: $transition-base;
  }

  &.sticky {
    @include media-breakpoint-up(xl) {
      padding-top: 50px;
    }
  }
}


/* Containers
   ========================================================================== */
.container-fluid { max-width: 2000px; }

/* Main
   ========================================================================== */
.main {
  body.home & {
    padding-top: 0;
  }
  
  /* Headlines */
  h1 {
    @extend .tt-content-h1;
  }
  h2 {
    @extend .tt-content-h2;
  }
  h3 {
    @extend .tt-content-h3;
  }
  h4 {
    //@extend .tt-content-h4;
  }
  h5 {
    //@extend .tt-content-h5;
  }
  h6 {
    //@extend .tt-content-h6;
  }

  /* Paragraph */
  p {
    //@extend .tt-content-text;
  }

  /* List */
  ul,
  ol {
    li {
      a {}
    }
  }
}

// links
a {
  &:hover {
    text-decoration: none;
  }
}