.default {
	
	&.service_overview {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
	}

	
 
	&.service_category {

		h1:empty {
			display: none;
	 }

		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			@extend .beachy;
		}

		.service_category_detail {
			@include make-row();
			@extend .m-0;

			.info {

				@include make-col-ready();
				@include make-col(12);

				@extend .mb-4;
				@extend .pr-lg-5;
			}

			.content {
				@include make-col-ready();
				@include make-col(12);
				@extend .d-flex;
        @extend .flex-column;


				@extend .mb-4;
				@extend .pl-lg-5;	
			}
			.btn-back {
				@extend .btn;
				@extend .align-self-end;
				@extend .mb-3;
			}
		}
		
		
	}

	&.service_detail {
		
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			@extend .beachy;
			
		}
			
		.service_detail {
			@include make-row();
			@extend .m-0;

			.info {
				@include make-col-ready();
				@include make-col(12);


				@extend .mb-4;
				@extend .pr-lg-5;

			}

			.content {
				@include make-col-ready();
				@include make-col(12);
				@extend .d-flex;
        @extend .flex-column;


				@extend .mb-4;
				@extend .pl-lg-5;

				.btn-back {
					@extend .btn;
					@extend .align-self-end;
					@extend .mb-3;
				}
			}
		}
	}
	&.service_detail {
		.section_one {
			.container-fluid {
			}
		}
	}

	.service_category_overview {
		position: relative;
		@extend .px-5;
		@extend .m-0;
		.item {
			@extend .my-5;
		}
		
	}
}