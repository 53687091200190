/* ==========================================================================
   Polaroids
   ========================================================================== */

  .card {

    .section_polaroids & {
      background: none;
      border: none;
  
      /* Card - Image */
      .card-image {
        background: $white;
        padding: 15px!important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        img {
          border-radius: 0;
        }
        
      }
    
      /* Card - Body */
      .card-body {
        .card-caption {
    
          .card-title {
            @extend .text-center;
            font-size: 1.875rem; 
            font-family:  $font-family-secondary;
            font-weight: 600;
            line-height: 2rem;
          }

          .card-title-link,
          .card-btn,
          .card-subtitle {
            @extend .d-none;
          }
        }
      }
    }
    &.wide {
      .card-image {
        padding: 15px!important;
      }
    }
  }

  // Positioning
  .section_polaroids {
    .grid {
      .item {
        transition: $transition-base;
        &:nth-of-type(2) {
          transform: rotate(-4deg);
          padding-top: 50px;
        }
        .card {
          margin: 0 -24px;
        }
        &:hover {
          transform: scale(1.025);
        }
      }
    }
  }
  .section_polaroids.square {
    .grid {
      @extend .justify-content-center;
      .item {
        &:nth-of-type(4n+1) {
          transform: rotate(-5deg);
        }
        &:nth-of-type(4n+2) {
          transform: rotate(5deg);
        }
        &:nth-of-type(4n+3) {
          transform: rotate(-2deg);
        }
        &:nth-of-type(4n+4) {
          transform: rotate(-5deg);
        }
        .card {
          margin: 0 -15px;
        }
        &:hover {
          transform: scale(1.15);
        }
      }
    }
  }