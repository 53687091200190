.default {
	&.blog_post_overview {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
	}

	&.blog_post_detail {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
			
		.blog_detail {
			@include make-row();
			@extend .m-0;

			.info {
				@include make-col-ready();
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@extend .mb-4;
				@extend .pr-lg-5;

				.btn-back {
          @extend .btn;
          //@extend .btn-pink;
          @extend .align-self-end;
          @extend .mb-3;
				}
				.extra {
					margin-top: 20px;
					@extend .tt-card-price;
				}
				
			}

			.content {
				@include make-col-ready();
        @include make-col(12);
        @extend .d-flex;
        @extend .flex-column;
        @extend .mb-4;
        @extend .pl-lg-5;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}
			
		}
	}
	&.blog_post_detail {
		.section_one {
			.container-fluid {
				max-width: $container-detail-mw;
			}
		}
	}
}
