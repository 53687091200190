.default {
	&.assortiment_category_overview,
	&.assortiment_overview {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			@include make-row();

      .item {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(3);
        }

        @extend .mb-3;
        @extend .mb-sm-4;
      }
		}
	}
	&.assortiment_category_detail,
	&.assortiment_detail {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
      @extend .py-sm-5;
      
      @extend .beachy;
		}

		.assortiment_detail {
			@include make-row();
			@extend .m-0;
      
      .info {
        @include make-col-ready();
        @include make-col(12);
        @extend .mb-4;
        @extend .pr-lg-5;
        
       
				.price {
					@extend .tt-card-price;
				}
      }
      
      .content {
        @include make-col-ready();
        @include make-col(12);
        @extend .d-flex;
        @extend .flex-column;
        @extend .mb-4;
        @extend .pl-lg-5;

        
        .btn-back {
          @extend .btn;
          //@extend .btn-pink;
          @extend .align-self-end;
          @include media-breakpoint-up(md) {
            margin-top: -30px!important;
            margin-bottom: 60px!important;
          }
        }
      }
		}
	}

	&.assortiment_category_detail,
	&.assortiment_detail {
		.section_one {
			.container-fluid {
			}
		}
	}
}
