/* ==========================================================================
   Typography
   ========================================================================== */

/* Menu
   ========================================================================== */
.tt-menu-toggler {}
.tt-menu-toggler--hover {}

.tt-menu-main {
  font-size: $font-size-secondary;
  font-weight: bold;
  text-transform: uppercase;
}
.tt-menu-main--hover {}
.tt-menu-main--active {}
.tt-menu-main--last {}

.tt-menu-sub {
  text-transform: initial!important;
}
.tt-menu-sub--hover { 
  color: $theme-color-tertiary!important; 
}
.tt-menu-sub--active {
  color: $theme-color-tertiary!important; 
}
.tt-menu-sub--last {

}


/* Content
   ========================================================================== */
.tt-content-h1 { 
  font-size: $h1-font-size; 
  font-family:  $font-family-secondary;
  font-weight: 600;
  line-height: 2rem;
  @extend .mb-4;
  @include media-breakpoint-down(md) {
    font-size: $h1-font-size-sm; 
    line-height: 1.2rem;
  }
}
.tt-content-h2 { 
  font-size: $h2-font-size; 
  font-family:  $font-family-secondary;
  line-height: 2rem;
  color: $theme-color-tertiary;
  font-weight: bold;
  @extend .mb-4;
  @include media-breakpoint-down(md) {
    font-size: $h1-font-size-sm; 
    line-height: 1.2rem;
  }
}
.tt-content-h3 { 
  font-size: $h3-font-size; 
  line-height: 1.5rem;
  font-style: italic;
  @extend .mb-1;
  @extend .mb-sm-4;
  @include media-breakpoint-down(md) {
    font-size: $h3-font-size-sm; 
  }
}
.tt-content-h4 {
  font-size: $h4-font-size; 
  font-family:  $font-family-secondary;
  font-weight: bold;
  @extend .mb-4;
  @include media-breakpoint-down(md) {
    font-size: $h1-font-size-sm; 
  }
}
.tt-content-h5 {
  @extend .tt-content-h4;
}
.tt-content-h6 {}

.tt-content-text {}
.tt-content-text--white {
  //@extend .tt-content-text;
}

/* Banner
   ========================================================================== */
.tt-banner-title {}
.tt-banner-subtitle {}
.tt-banner-text {}


/* Card
   ========================================================================== */
.tt-card-title {}
.tt-card-title--white {
  //@extend .tt-card-title;
  color: $white;
}

.tt-card-subtitle {}
.tt-card-subtitle--white {
  //@extend .tt-card-subtitle;
  color: $white;
}

.tt-card-text {}
.tt-card-text--white {
  //@extend .tt-card-text;
  color: $white;
}
.tt-card-price {
  @extend .tt-content-h3;
  @extend .d-block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px!important;
}