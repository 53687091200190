.default {
	&.accommodation_overview,
	&.accommodation_category_overview,
	&.location_accommodation_overview {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
	}

	&.accommodation_book {
		.section_one {
			@extend .px-3;
			@extend .px-sm-5;
			@extend .pb-5;

			padding-top: 75px;

			@include media-breakpoint-up(md) {
				padding-top: 150px;
			}
			
		}
	}

	&.accommodation_arrangement_overview {
		.section_one {
			@extend .px-3;
			@extend .py-3;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
	}

	&.accommodation_search_book {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
	}
	&.accommodation_category_detail,
	&.accommodation_detail {
		.container-holder {
			@extend .position-relative;
		}

		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			@extend .beachy;

			.link[href^='#'] {
				@extend .position-absolute;
				top: 3rem;
				left: 2rem;
				z-index: 10;

				@extend .btn;
				@extend .mt-0;

				@extend .add-arrow--after;
  			@extend .add-arrow--after-down;

				@include media-breakpoint-up(sm) {
					right: 3.75rem;
					left: inherit;
				}

				@include media-breakpoint-up(xl) {
					right: 10vw;
				}
			}
		}

		.accommodation_detail {
			@include make-row();
			@extend .pb-5;
			@extend .m-0;


			.info {
        @include make-col-ready();
        @include make-col(12);
        @extend .mb-4;
				@extend .pr-lg-5;
				
				padding-top: 6rem !important;
				
				@include media-breakpoint-up(sm) {
					padding-top: 3rem !important;
				}
				
				.btn-back {
          @extend .btn;
          //@extend .btn-pink;
          @extend .align-self-end;
          @extend .mb-3;
        }

				&.full-width {
					@include media-breakpoint-up(md) {
						@include make-col(12);
					}
				}
      }

			.content {
        @include make-col-ready();
        @include make-col(12);
        @extend .d-flex;
        @extend .flex-column;
        @extend .mb-4;
        @extend .pl-lg-5;

        
				
        .btn-back {
          @extend .btn;
          //@extend .btn-pink;
          @extend .align-self-end;
          @extend .mb-3;
        }
      }

			.tommy {
        @include make-col-ready();
        @include make-col(12);
				@extend .mt-3;
				position: relative;
				@extend .p-5;
				&::before {
					@extend .slant;
					top: -2.5vw;
					height: 2.5vw;
					background: transparent url('/images/white_top.svg')  center 0 no-repeat;
					background-size: cover;
				}
      }
		}
	}
	
	&.accommodation_search_book,
	&.accommodation_detail {
		.section_one {
			.container-fluid {
			}
		}
	}

	&.accommodation_book_succes,
	&.accommodation_book_error,
	&.accommodation_book_terms_and_conditions {
		.section_one {
			@extend .px-3;
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;
		}
	}
}
