@mixin carousel-height {
	min-height: $eyecatcher-height-small;
	@include media-breakpoint-down(md) {
		min-height: $eyecatcher-height-small-sm;
	}

	.section_slider &,
	.large & {
		min-height: $eyecatcher-height-big;
		@include media-breakpoint-down(md) {
			min-height: $eyecatcher-height-big-sm;
		}
	}
	
}

.big-py {
	padding-top: $py-big-s;
	padding-bottom: $py-big-s;
	@include media-breakpoint-up(lg) {
		padding-top: $py-big-l;
		padding-bottom: $py-big-l;
	}
}