.btn {
  margin: 10px 0 0 0;
  @extend .px-4;
  background: $theme-color-tertiary;
  @extend .border-0;
  
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: $white;

  &:hover {
    background: $theme-color-primary-dark;
    color: $white;
  }

}
.btn-wide {
  min-width: 240px;
}

.btn-link {
  &--primary {
    color: $tertiary;

    &:hover {
      color: $primary;
    }
    
  }

  &--white {
    color: $white;

    &:hover {
      color: $primary;
    }
  }
  
}

.btn-back {
  @extend .mb-3;
  @extend .btn-link--primary;
  @extend .add-arrow--before;
  @extend .add-arrow--before-r;
}