  // carousel
  .carousel {

    // Gallery home
  	.intro-gallery & {

  		.carousel-inner {
  			.carousel-item {
  				.container {
  					.carousel-caption {
  						@extend .d-none;
  						color: $body-color;
  					}
  				}
  			}
      }
      .carousel-indicators {
        @extend .d-none;
      }
      .carousel-control-prev, 
      .carousel-control-next {
        top: 15px;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        color: #fff;
        border-radius: 100%;
        opacity: 1;
        span {
          background-size: 12px;
        }
        &:hover {
          opacity: 0.5;
        }

      }
      .carousel-control-prev { left: 15px; right: inherit; }
      .carousel-control-next { left: 50px; right: inherit; }
    }

    // Home slider
  	.section_banner-overlay & {

  		.carousel-inner {
  			.carousel-item {
          .carousel-holder {
            @include media-breakpoint-down(lg) {
              align-items: flex-end!important;
              margin-bottom: 15px;
            }
            .container {
                        

              .carousel-caption {
                @include media-breakpoint-up(lg) {
                  position: absolute;
                  bottom: 60px;
                  right: 60px;
                  max-width: calc(50% - 30px);
                }

                background: $theme-color-secondary;
                color: $white;
                @extend .p-4;

                @extend .d-flex;
                @extend .flex-column;
                
                text-align: left;
                font-size: 0.875rem;
                
                
                .carousel-title {
                  @extend .mb-3;
                }

                .carousel-text {
                  @include media-breakpoint-down(sm) {
                    display: none;
                  }
                }

                .carousel-btn {
                  margin-top: auto;
                  font-weight: bold;
                  color: $white;
                }

              }
            }
          }

  			}
      }
      .carousel-indicators {
      }
      .carousel-control-prev, 
      .carousel-control-next {
        @extend .d-none;

      }
  	}

  }
