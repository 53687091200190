.add-arrow {
  &--before {
    &::before {
      margin-right: 10px;

      font-family: "Font Awesome 5 Pro";
      font-weight: $font-weight-bold;
      content: "\f061";

      transition: $transition-base;
    } 

    &-r {
      &::before {
        content: "\f060";
      }
    }

    &-down {
      &::before {
        content: "\f063";
      }
    }

    &:hover {
      &::before { margin-right: 15px; }
    }
  }

  &--after {
    &::after {
      margin-left: 10px;
      
      font-family: "Font Awesome 5 Pro";
      font-weight: $font-weight-bold;
      content: "\f061";

      transition: $transition-base;
    }

    &-r {
      &::after {
        content: "\f060";
      }
    }

    &-down {
      &::after {
        content: "\f063";
      }
    }

    &:hover {
      &::after { margin-left: 15px; }
    }
  }

  &--none {
    &::before,
    &::after {
      @extend .d-none;
    }
  }
}