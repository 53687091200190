.eyecatcher {
	position: relative;
	@include carousel-height();
	background-color: #EEEEEE;
  
	.container-default {
		@extend .px-0;
	}

	.carousel-caption {
		text-shadow: 0 2px 12px rgba(0, 0, 0, 0.75);
		
		.carousel-title {
			@extend .tt-content-h1;
			color: $white;
			font-size: 4.125rem;
			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}
			line-height: 3rem;;
			@extend .text-center;
		}
	}
	.carousel {
		.carousel-item-bg {
			.carousel-holder {
				@extend .align-items-end;
				padding-bottom: 150px;
				@include media-breakpoint-down(md) {
					padding-bottom: 50px;
				}
			}
		}
	}
	
	&::before {
		position: absolute;
		top: 50%;
		left: 50%;

		display: block;
		margin-top: -25px;
		margin-left: -25px;

		font-family: "Font Awesome 5 Pro";
		font-size: 50px;
		line-height: 50px;
		color: #BBBBBB;

		content: "\f1ce";

		animation: fa-spin 2s infinite linear;
	}
  

	.carousel-control-prev, 
  .carousel-control-next {
		@extend .d-none;
	}
	.carousel-indicators {
		margin-bottom: 50px;
	}
}