.mini-sab {
  @extend .w-100;
  z-index: 100;
  @extend .px-4;
  @extend .px-sm-5;
  @extend .pb-3;
  transition: all 0.5s ease;

  @include media-breakpoint-down(sm) {
    background: #f9f7ef;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    margin: -140px 0 0;
    body.home & {
      margin: -160px 0 0;
    }
  }
  
  .container-one-column {
    .column {
      @extend .px-3;
      @extend .mx-auto;
      @include make-col(12);
      padding: 30px;

      @include media-breakpoint-up(lg) {
        @include make-col(10);
        padding: 0;
      }

      .tommy {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
        @include media-breakpoint-down(md) {
          box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .sticky-sab & {
    @include media-breakpoint-up(lg) {
      position: fixed;
      top: 60px;
      margin: 0!important;
    }
  }
}

.header + .mini-sab {
  margin-top: 130px !important;
  padding-top: 0px !important;

  @include media-breakpoint-up(lg) {
    position: relative;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 0 !important;
    padding-top: 100px !important;
  }
  @include media-breakpoint-down(xs) {
    padding-top: 50px !important;
  }
  .sticky-sab & {
    margin-top: 0 !important;
    
    @include media-breakpoint-up(lg) {
      position: fixed;
      top: 60px;
      margin: 0;
    }
  }
}

// BEACHY DETAIL LOOK
.beachy {
  position: relative;
  padding: 0!important;


  > .container-fluid {
    max-width: 100%;
    padding: 0!important;
  }
  &::before {
    @extend .slant;
    top: -2.5vw;
    height: 2.5vw;
    background: transparent url('/images/blue_top.svg')  center 0 no-repeat;
    background-size: cover;
  }

  .info {

    position: relative;
			@extend .bg-blue;
			@extend .px-3;
			@extend .px-sm-5;
			@extend .py-5;

    &::after {
      @extend .slant;
      bottom: -2.3vw;
      height: 2.5vw;
      background: transparent url('/images/blue_bottom.svg')  center 0 no-repeat;
      background-size: cover;
    }

    .btn-back {
      @extend .btn;
    }

  }
  .content {
    background: #E2E0D8 url('/images/bg-beach.jpg')  center 0 no-repeat;
    @extend .px-5;
    @extend .py-5;
    margin: -25px 0 0!important;

    @include media-breakpoint-up(lg) {
      padding-top: 80px!important;
      padding-bottom: 80px!important;
    }

    .gallery {
      .item, .highlight {
        transition: all 0.5s ease;
        @extend .p-0;
        a {
          background: $white;
          padding: 15px!important;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
          @extend .d-block;
          img {
            border-radius: 0;
          }
        }
        &.first {
          transform: rotate(-2deg);
          padding-top: 20px!important;
        }
        &.before-last {
          transform: rotate(3deg);
          padding-top: 20px!important;
        }
        &.last {
          transform: rotate(-2deg);
          padding-top: 20px!important;
        }
        &:hover {
          transform: scale(1.025);
        }
      }
     
      .highlight {
        transform: rotate(-2deg);
        padding: 0!important;
        @include media-breakpoint-up(md) {
          padding: 0 20% 30px!important;
        }
        &:hover {
          transform: none;
        }
      }
      
    }
  }
  
}