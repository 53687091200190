.page_block {
  &.grid {
    @include make-row();
    
    @extend .mb-n3;

    .item {
      @include make-col-ready();
      @include make-col(12);

      @extend .mb-3;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
      .section_polaroids & {
        @include media-breakpoint-up(sm) {
          @include make-col(12);
        }

        @include media-breakpoint-up(md) {
          @include make-col(4);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }

        @include media-breakpoint-up(xl) {
          @include make-col(4);
        }
      }
      .section_polaroids.square & {
        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(3);
        }

        @include media-breakpoint-up(xl) {
          @include make-col(3);
        }
      }
    }
  }

  &.card-columns {
    column-count: 1;
    column-gap: 30px;

    .card { margin-bottom: 30px; }

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }

    @include media-breakpoint-up(md) {
      column-count: 2;
    }

    @include media-breakpoint-up(lg) {
      column-count: 3;
    }

    @include media-breakpoint-up(xl) {
      column-count: 4;
    }
  }

  &.inspiration {
    @include make-row();

    @extend .mb-n3;
    
    .item {
      @include make-col-ready();
      @include make-col(12);

      @extend .mb-3;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
      
      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }
    }
  }

  &.footer_text {
    
  }

  &.footer_list {

  }

  &.footer_link,
  &.footer_logolink {
    .list {
      @extend .d-flex;
      @extend .flex-wrap;
  
      .list-item {
        @extend .mx-2;
      }
    }
  }
}