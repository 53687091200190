/* ==========================================================================
   Bootstrap-carousel
   ========================================================================== */

.carousel {
  /* Carousel - Normal */
  .carousel-item {
    @include carousel-height();
    
    .carousel-holder {
      @include carousel-height();

      .carousel-caption {
        @extend .text-left;
      }
    }
  }

  /* Carousel - Background */
  .carousel-item-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .carousel-holder {
      @extend .d-flex;
      @extend .align-items-center;

      .carousel-caption {
        position: relative;
        right: unset;
        bottom: unset;
        left: unset;
      }
    }
  }


  .section_slider & {

    .carousel-holder {
      .container {
        @extend .w-100;
        max-width: 100%;
        @extend .px-5;

        .carousel-caption {
          margin-top: 15%;
          text-shadow: 6px 6px 6px rgba(0, 0, 0, 0.5);
          .carousel-title {
            @extend .tt-content-h3;
            font-family: $font-family-base;
            color: $white;
            margin: 10px 0!important;
          }
          .carousel-subtitle {
            @extend .tt-content-h1;
            font-style: normal;
          }

        }
      }
    }

    // turn of controls
    .carousel-control-prev, 
    .carousel-control-next {
      @extend .d-none;
    }

  }
}