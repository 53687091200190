/* ==========================================================================
   Navigation
   ========================================================================== */

   .navbar-nav {
    .nav-item {
      .nav-link {
        color: $theme-color-primary!important;
        transition: $transition-base;
        padding: $menu-py $menu-px!important;
        @extend .d-flex;
        @extend .align-items-center;
        .sticky & {
          padding: $menu-py-sm $menu-px!important;
        }
        @include media-breakpoint-down(lg) {
          display: block!important;
          padding: 14px 18px !important;
          text-align: right;
        }

      }
      &.active, &:hover {
        > .nav-link {
          @include media-breakpoint-up(xl) {
            color: $theme-color-secondary;
            background: $white;
          }
        }
      }
      .dropdown-menu {
        @include border-radius(0);  
        @extend .border-0;
        margin-left: 10px; 

        @include media-breakpoint-up(xl) {
          background: $white;
          box-shadow: 0 12px 12px 1px rgba(0, 0, 0, 0.2);
          margin-left: 0;
        }
        @include media-breakpoint-down(xl) {
          background: none;
        }


        .nav-item {
          .nav-link {
            padding: 5px 20px!important;
            @extend .tt-menu-sub;
            &:hover {
              @extend .tt-menu-sub--hover;
            }
          }
        }

      }
      
    }
    &.socials {
      @include media-breakpoint-down(lg) {
        display: none;
      }
      .nav-item {
        .nav-link {
          color: $white!important;
          font-size: 1.25rem;
          padding-left: 10px!important;
          padding-right: 10px!important;
        }
        &.active, &:hover {
          > .nav-link {
            color: $theme-color-primary!important;
            background: none;
          }
        }
      }
    }
    &.language {
      @extend .flex-row;
      .nav-item {
        .nav-link {
          padding-left: 10px!important;
          padding-right: 10px!important;
          @include media-breakpoint-down(sm) {
            padding-left: 5px!important;
            padding-right: 5px!important;
          }
          img {
            box-shadow: 0 0 0 1px $white;
          }
        }
        &.active, &:hover {
          > .nav-link {
            background: none;
          }
        }
      }
    }
  }
  