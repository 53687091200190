$tommy_color_primary: $theme-color-primary;
$tommy_color_primary_dark: darken($tommy_color_primary, 0.4);
$tommy_color_secondary: $theme-color-secondary;

html, body, h2, h3, h4, h5 { font-size: inherit; }

/* custom - op basis van TommyBookingSupport */
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:hover { color: #fff; background-color: $tommy_color_primary; }
#TommyBookingSupport .tbs-btn-info { color: #fff;  background-color: $tommy_color_primary; border-color: $tommy_color_primary; }
#TommyBookingSupport .tbs-btn-info:hover { background-color: $tommy_color_primary_dark; border-color:  $tommy_color_primary_dark; }
#TommyBookingSupport .tbs-btn-success { color: #fff; background-color: $tommy_color_primary; border-color: $tommy_color_primary; }
#TommyBookingSupport .tbs-btn-success:hover { color: #fff; background-color: $tommy_color_primary_dark; border-color: $tommy_color_primary_dark; }
#TommyBookingSupport .tbs-btn-info, #TommyBookingSupport .tbs-btn-success { transition: all 0.5s ease; }
#TommyBookingSupport .mapplic-map a.mapplic-pin {filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.8)); }

body #TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected { background-color: $tommy_color_secondary; }


#TommyBookingSupport .tbs-row.tbs_persoonscategorien, #TommyBookingSupport .tbs-row.tbs_accommodaties, #TommyBookingSupport .tbs-row.tbs_kalenders, #TommyBookingSupport .tbs-container-fluid.tbs_kassabon, #TommyBookingSupport .tbs-row.tbs_artikelen, #TommyBookingSupport .tbs-row.tbs_persoon, #TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
    background-color: rgb(245, 245, 245) !important;
}

/* 
  tommy default 
  source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
*/
#TommyBookingSupport .tbs-container-fluid { padding: 0; }
#TommyBookingSupport .tbs-navbar-collapse { padding: 0; }
#TommyBookingSupport .tbs-navbar-nav>li>a { padding-top: 10px; padding-bottom: 10px; }

#TommyBookingSupport .tbs-navbar { min-height: auto; overflow: hidden; }
#TommyBookingSupport * a { text-decoration: none; }
#TommyBookingSupport .tbs-legenda { background: none; border: none; }

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2)  {
    padding: 20px 0;
    margin: 20px 0;
    background-color: #f8f8f8;
    border-radius: 5px;
}
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon { margin: 33px 0; padding: 20px; }
#TommyBookingSupport .tbs_overlay-mask { border-radius: 5px; }

#TommyBookingSupport .tbs-calendar { margin: 20px 0; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar { cursor: pointer; transition: all 0.5s ease; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover { opacity: 0.8; }
#TommyBookingSupport .tbs-calendar table > thead > tr > th { border: none; }
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected { color: #fff; background-color: #105251; }

#TommyBookingSupport .tbs-row h2 { margin-bottom: 0; }
#TommyBookingSupport .tbs-btn-block+.tbs-btn-block { margin: 0; }
#TommyBookingSupport .tbs_overzicht .tbs-row h2 { margin-bottom: 15px ; }

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row { margin-top: 15px; }

#TommyBookingSupport .tbs-alert-danger { color: #E91E63; background-color: rgba(233, 30, 99, 0.09);  border: none; margin: 0 15px 15px;  padding: 10px 35px 10px 10px; }
#TommyBookingSupport .tbs-has-error .tbs-form-control { border-color: rgb(244, 67, 54); }

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row { margin-bottom: 15px; }
#TommyBookingSupport .tbs-label { font-size: 100%; margin-right: 5px; }
#TommyBookingSupport .tbs_kassabon .tbs-hr { border-top: 1px solid rgba(0,0,0,0.1); }
#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate { font-weight: 400; }
#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount { font-size: 32px; line-height: 50px; }
#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row { margin: 0 0 15px 0; }

/* resonsive mobile */
@media (max-width: 767px) {

    #TommyBookingSupport .tbs-calendarPrevious span.tbs-translate, 
    #TommyBookingSupport .tbs-calendarNext span.tbs-translate { display: none; }

}


/* custom - op basis van TommyBookingSupport MINI Widget */
.mini-zeb .tommy-zeb-wrapper * { font-family: 'Cabin',sans-serif !important;}
.mini-zeb .tommy-zeb-wrapper .tommy-zeb-left {
    background: none;
    color: #fff;
    padding: 20px 0!important;
}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope { display: inline;}
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope:first-child { display: inline;}

.mini-zeb .tommy-zeb-wrapper-mini h2.ng-binding { display: inline;}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope { padding: 0;}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left .tommy-zeb-accommodation-types {
    flex: 1 1 100px;
    max-width: inherit;
}
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left .tommy-zeb-accommodation-types {
    display: none !important;
    margin-right: 30px;
    padding-right: 20px;

    border-bottom: none;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left .tommy-zeb-accommodation-types .ng-isolate-scope {
    width: 100%;
}

@media (min-width: 1200px) {
    .mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .tommy-zeb-accommodation-person-category-label { line-height: 28px;}
    .mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories select { float: right;}
    .content .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 250px; }
    .content .tommy-zeb-wrapper .tommy-zeb-result-details {width: 390px;float: left;}
}
@media (min-width: 980px) and (max-width: 1199px) {
    .mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini * { display: flow-root; }
    .mini-zeb .tommy-zeb-wrapper .tommy-zeb-date-begin { margin-bottom: 0;}
    .content .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 180px; }
    .content .tommy-zeb-wrapper .tommy-zeb-result-details {  }
}
.mini-zeb .tommy-zeb-wrapper select option { display: block;}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, 
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date { border-right-color: #fff;}

body .tommy-zeb-wrapper .tommy-zeb-left { background: $tommy_color_primary; border: 0; }
body .tommy-zeb-wrapper .tommy-zeb-left h1 {
    color: #fff;
    font-weight: 400;
    line-height: 1;
    font-family: 'Oswald', sans-serif !important;
}
body .tommy-zeb-wrapper * h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif !important;
    display: none!important;
}
body .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input { padding: 3px 6px;}
body .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding { color: #466228;}
body .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding { color: $tommy_color_primary;}
body .tommy-zeb-wrapper-mini button.tommy-zeb-btn { 
    margin-top: 0!important; 
    background-color: $tommy_color_secondary;
    color: #fff;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
    font-size: .875rem;
    margin-left: 60px;
    cursor: pointer;
}
body .tommy-zeb-wrapper .tommy-zeb-btn { 
    margin-top: 15px!important; 
    background-color: $tommy_color_secondary;
    color: #fff;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
    font-size: .875rem;
    cursor: pointer;
}

body .tommy-zeb-wrapper .tommy-zeb-btn:hover {
    background-color: $tommy_color_primary_dark;
    color: #fff;
}
body .tommy-zeb-wrapper .tommy-zeb-btn:hover { background-color: #f4cd29; color: $tommy_color_primary; }