/* ==========================================================================
   Footer
   ========================================================================== */

.footer {

/* Footer-top
   ========================================================================== */
   .footer-top {
    @extend .py-5;
    @extend .px-3;
    @extend .px-sm-0;
  
    @extend .bg-blue;
    color: $white;

    .column {
      h5 {
        @extend .tt-content-h5;
        color: $theme-color-tertiary;
      }
    }
    .column.one {
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
    .column.two,
    .column.three,
    .column.four {
      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(2);
      }
    }

    a {
      color: $white;
      &:hover {
        color: $secondary;
      }
    }

   
  }

/* Footer-bottom
   ========================================================================== */
   .footer-bottom {
    @extend .bg-blue;
    @extend .py-3;
  
    .list {
      @extend .justify-content-center;
      
      .list-item {
        border-right: 1px solid $white;
        padding-right: 15px;
        font-size: 0.75rem;
        line-height: 0.75rem;
        
        &:last-child {
          border: none;
        }
        a {
          color: $white;
        }
        
      }
    }
  }
}