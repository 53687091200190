.gallery {
  @extend .m-0;
  
  .gallery-holder {
    @include make-row();

    .highlight {
      @include make-col-ready();
      @include make-col(12);

      transition: $transition-base;

      a {
        @extend .d-block;
      }

      .img-fluid {
        @extend .w-100;
      }

      &:hover {
        transform: scale(1.025);
      }
    }

    .item {
      @include make-col-ready();
      @include make-col(6);

      @extend .mt-4;

      &.extra-thumbs {
        a {
          position: relative;
          @extend .d-block;

          .remaining {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 99;

            display: none;
            width: 100%;
            
            display: flex;
            justify-content: center;
            align-items: center;

            background: rgba(0, 0, 0, 0.62);

            font-size: 50px;
            color: #fff;
            
            @include media-breakpoint-down(lg) {
              font-size: 30px;
            }

            @include media-breakpoint-down(md) {
              font-size: 50px;
            }

            &::before {
              content: '+';
            }
          }
        }

        .img-fluid {
          @extend .w-100;
        }

        @include media-breakpoint-up(md) {
          &.first,
          &.before-last {
            .remaining {
              display: none!important;
            }
          }
          &.last {
            .remaining {
              display: flex!important;
            }
          }
        }
        
        @include media-breakpoint-down(md) {
          &.before-last {
            .remaining {
              display: flex!important;
            }
          }
          &.first {
            .remaining {
              display: none!important;
            }
          }
          &.last {
            display: none!important;
          }
        }
      }

      &:hover {
        transform: scale(1.025);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }
  }
}