/* ==========================================================================
   Header
   ========================================================================== */

.header {
  position: relative;
  z-index: 999;

  transition: $transition-base;

  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

/* Header-main
   ========================================================================== */
  .header-main {
    background: $bg-yellow;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

    .container-navbar {
      @extend .px-0;
    }

    .column.navbar {
      @extend .navbar-expand-xl;
      @extend .navbar-light;
      @extend .py-0;
      @include media-breakpoint-down(lg) {
        padding: 0!important;
      }
    }

    .logo {
      position: absolute;
      top: 15px;
      left: 65px;
      max-width: 280px;
      transition: all 0.5s ease;

      @include media-breakpoint-down(sm) {
        left: 15px;
        max-width: 240px;
        top: 15px;
      }
      @include media-breakpoint-down(xs) {
        left: 10px;
        max-width: 140px;
        top: 25px;
      }

      a {
        @extend .d-block;

        img {
          @extend .w-100;
        }
      }
    }

    .navbar-toggler {
      @extend .border-0;
      color: $theme-color-primary!important;
      @extend .ml-auto;

      @include media-breakpoint-down(lg) {
        height: 80px;
        background: #F9F7EF;
        padding: 0 25px;
        border-radius: 0;
      }
      @include media-breakpoint-down(sm) {
        padding: 0 15px;
      }

      .navbar-toggler-icon {
        width: auto;
        height: auto;
        background-image: none!important;
      }
      .navbar-toggler-icon::after {
        content: 'menu';
        text-transform: uppercase;
        font-family: $font-family-secondary;
        margin-left: 10px;
      }
    }
    
    .navbar-collapse {
      .navbar-nav { 
        @extend .ml-auto;
        @extend .tt-menu-main;

        .nav-item {
          &.dropdown {
            margin-bottom: -5px;

            .dropdown-menu {
              min-width: 100%;
              margin: 0;
            }
  
            &:hover {
              .dropdown-menu {
                display: block;
              }
            }
          }
        }
      }
    }

    .language {
      .nav-item {
        &:nth-child(1) { order: 1; }
        &:nth-child(2) { order: 3; }
        &:nth-child(3) { order: 2; }
      }
    }
    
    // Responsive ordering
    @include media-breakpoint-down(lg) {

      .site_switcher {
        order: 1;
        margin-left: auto;
      }
      .navbar-toggler {
        order: 2;
        margin-left: 15px!important;
      }
      .navbar-collapse {
        order: 3;
      }

    }
     
    
  }

/* Header - Sticky
   ========================================================================== */
  &.sticky {
    @include media-breakpoint-up(xl) {
      position: fixed;
    }

    .header-main {
      .logo {
        @include media-breakpoint-up(xl) {
          max-width: 65px;
          top: 8px;
        }
      }
    }
  }
}