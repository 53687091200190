.slant {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	z-index: 10;
	background-size: cover;
}

.default {
	&.home {
		.section_intro {
			position: relative;
			@extend .bg-blue;
			@extend .px-3;
			@extend .px-sm-5;
			@extend .py-5;

			.container-two_columns {
				@extend .py-3;
			}
			&::before {
				@extend .slant;
				top: -2.5vw;
				height: 2.5vw;
				background: transparent url('/images/blue_top.svg')  center 0 no-repeat;
				background-size: cover;
			}
			&::after {
				@extend .slant;
				bottom: -2.3vw;
				height: 2.5vw;
				background: transparent url('/images/blue_bottom.svg')  center 0 no-repeat;
				background-size: cover;
			}
		}

		.section_polaroids.sand {
			background: #E2E0D8 url('/images/bg-beach.jpg')  center 0 no-repeat;
			@extend .px-5;
			@extend .py-5;
			@include media-breakpoint-up(lg) {
				padding-top: 80px!important;
			}
		}

		.section_content {
			position: relative;
			@extend .px-3;
			@extend .px-sm-5;
			@extend .py-5;
			&::before {
				@extend .slant;
				top: -2.5vw;
				height: 2.5vw;
				background: transparent url('/images/white_top.svg')  center 0 no-repeat;
				background-size: cover;
			}

		}
		.section_polaroids.square {
			@extend .px-5;
			@extend .pb-3;
			@extend .pb-sm-5;
		}

		.section_reviews {
			@extend .px-3;
			@extend .px-sm-5;
			@extend .py-3;
			@extend .py-sm-5;

			.container-default {
				@extend .text-center;
			}
		}

		.section_slider {
			@extend .p-0;
			
		}

		.section_icons {
			position: relative;
			@extend .bg-cyan;
			@extend .pt-5;
			@extend .pb-2;
			@extend .px-1;
			@extend .px-sm-5;
			&::before {
				@extend .slant;
				content: '';
				top: -2.5vw;
				height: 2.5vw;
				background: transparent url('/images/l_blue_top.svg')  center 0 no-repeat;
				background-size: cover;
			}
		}

		.section_map {
			position: relative;

			background: transparent url('/images/kaart.jpg') center center no-repeat;
			background-size: cover;
			height: 400px;
		
			.map_logo {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;

				a { pointer-events: none; }

				img { display: none; }
			}

			@include media-breakpoint-down(md) {
				p {
					img {
						display: none;
					}
				}
			}

			.caption {
				position: absolute;
				top: 15%;
				left: 60%;

				display: none;
				padding: 15px;
				background-color: #ffffff;
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);

				@include media-breakpoint-up(lg) {
					left: 57%;
				}

				@include media-breakpoint-up(xl) {
					left: 57%;
				}

				.title {
					@extend .mb-1;
					font-size: $font-size-base * 1.5;
					color: #0D8BCB;
				}

				p { font-size: $font-size-base * 0.875; }

				&.visible {
					display: none;

					@include media-breakpoint-up(md) {
						display: block;
					}
				}
			}
		}
	}
}
